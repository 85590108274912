"use client";

import {
  PayloadLexicalReactRenderer,
  type PayloadLexicalReactRendererContent,
} from "@atelier-disko/payload-lexical-react-renderer";
import { useFormState } from "react-dom";

import { LoadingButton } from "~/components/loading-button";
import { Textarea } from "~/components/textarea-autosize";
import { Input } from "~/components/ui/input";
import { Label } from "~/components/ui/label";
import { type Form } from "~/payload-types";
import { submitFormData } from "~/server/cms/forms";

interface Props {
  form: Form;
}

export function Form({ form }: Props) {
  const [state, dispatch] = useFormState(submitFormData, null);

  if (!form.fields) {
    return null;
  }

  if (state === true && form.confirmationType === "message") {
    return (
      <div className="mx-auto max-w-sm">
        <PayloadLexicalReactRenderer
          content={
            form.confirmationMessage! as PayloadLexicalReactRendererContent
          }
        />
      </div>
    );
  }

  return (
    <div className="mx-auto max-w-sm">
      <form action={dispatch}>
        <input type="hidden" name="formId" value={form.id.toString()} />
        <div className="flex flex-col gap-2">
          {form.fields.map((field) => (
            <RenderField key={field.id} field={field} />
          ))}
          <div className="flex flex-row justify-end">
            <LoadingButton>{form.submitButtonLabel ?? "Skicka"}</LoadingButton>
          </div>
        </div>
      </form>
    </div>
  );
}

interface RenderFieldProps {
  field: NonNullable<Form["fields"]>[number];
}

function RenderField({ field }: RenderFieldProps) {
  switch (field.blockType) {
    case "text":
    case "email":
      return (
        <div className="grid w-full items-center gap-1.5">
          <Label htmlFor={field.name}>
            {field.label ?? field.name}
            {field.required && <span className="text-red-500"> *</span>}
          </Label>
          <Input
            type={field.blockType}
            id={field.name}
            name={field.name}
            required={field.required ?? false}
          />
        </div>
      );
    case "textarea":
      return (
        <div className="grid w-full items-center gap-1.5">
          <Label htmlFor={field.name}>
            {field.label ?? field.name}
            {field.required && <span className="text-red-500">*</span>}
          </Label>
          <Textarea minRows={5} name={field.name} />
        </div>
      );
    default:
      return null;
  }
}

"use client";

import { PlayCircleIcon } from "lucide-react";
import Image from "next/image";
import Link from "next/link";

import { env } from "~/env.mjs";
import { type VideoAndTextEmbedBlock } from "~/payload-types";

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";
import { LexicalRender } from "./RichText";

type Props = Omit<VideoAndTextEmbedBlock, "blockName">;

export function VideoAndTextEmbedBlockRender({
  title,
  description,
  page,
  video: _video,
  videoThumbnail: _videoThumbnail,
}: Props) {
  const page2 = typeof page === "number" ? undefined : page;
  const video = typeof _video === "number" ? undefined : _video;
  const videoThumbnail =
    typeof _videoThumbnail === "number" ? undefined : _videoThumbnail;
  if (!video || !videoThumbnail) {
    return null;
  }
  return (
    <div className="relative isolate">
      <div className="relative isolate -z-10 my-16 sm:my-20">
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="mx-auto flex max-w-2xl flex-col gap-16 bg-white bg-opacity-50 p-6 ring-1 ring-black/10 sm:rounded-3xl sm:p-8 lg:mx-0 lg:max-w-none lg:flex-row lg:items-center lg:py-20 xl:gap-x-20 xl:px-20 dark:bg-black dark:bg-opacity-50 dark:ring-white/10">
            <Dialog>
              <DialogTrigger asChild>
                <button className="group relative h-96 w-full flex-none overflow-hidden rounded-2xl object-cover shadow-xl lg:aspect-square lg:h-auto lg:max-w-sm">
                  <Image
                    className="h-full w-full object-cover"
                    src={`${env.NEXT_PUBLIC_PAYLOAD_URL}${videoThumbnail.url}`}
                    alt="Video thumbnail"
                    width={640}
                    height={360}
                  />
                  <div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center bg-black bg-opacity-10 transition-all group-hover:bg-opacity-35">
                    <PlayCircleIcon className="h-12 w-12 text-white transition-all group-hover:h-16 group-hover:w-16" />
                  </div>
                </button>
              </DialogTrigger>
              <DialogContent className="w-full max-w-full sm:max-w-[90%]">
                <DialogHeader>
                  <DialogTitle>{title}</DialogTitle>
                </DialogHeader>
                <div>
                  <video
                    src={`${env.NEXT_PUBLIC_PAYLOAD_URL}${video.url}`}
                    preload="none"
                    controls
                    className="w-full"
                    autoPlay
                  />
                </div>
              </DialogContent>
            </Dialog>

            <div className="w-full flex-auto">
              <h2 className="font-heading text-3xl tracking-tight sm:text-4xl">
                {title}
              </h2>
              <LexicalRender
                content={description}
                className="mt-6 text-lg leading-8"
              />
              {page2 && (
                <div className="mt-10 flex">
                  <Link
                    href={page2.slug}
                    className="text-primary text-sm font-semibold leading-6"
                  >
                    Läs mer <span aria-hidden="true">&rarr;</span>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className="absolute inset-x-0 -top-16 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl"
          aria-hidden="true"
        >
          <div
            className="bg-primary aspect-[1318/752] w-[82.375rem] flex-none opacity-25"
            style={{
              clipPath:
                "polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)",
            }}
          />
        </div>
      </div>
    </div>
  );
}

"use client";

import {
  PayloadLexicalReactRenderer,
  type PayloadLexicalReactRendererContent,
} from "@atelier-disko/payload-lexical-react-renderer";
import { icons } from "@nextgen/shared";

import { cn } from "~/lib/utils";
import { type CustomTaskMarketingBlock } from "~/payload-types";

import { Badge } from "../ui/badge";

type Props = Omit<CustomTaskMarketingBlock, "blockName">;

export function CustomTaskMarketingBlockRender({
  title,
  subtitle,
  items,
}: Props) {
  if (!items) {
    return null;
  }

  return (
    <section className="container space-y-6 bg-slate-50 py-8 md:py-12 lg:py-24 dark:bg-transparent">
      <div className="mx-auto flex max-w-[58rem] flex-col items-center space-y-4 text-center">
        <h2 className="font-heading text-3xl leading-[1.1] sm:text-3xl md:text-6xl">
          {title}
        </h2>
        <p className="text-muted-foreground max-w-[85%] leading-normal sm:text-lg sm:leading-7">
          {subtitle}
        </p>
      </div>
      <div className="-m-4 grid auto-rows-min gap-4 sm:grid-cols-2 md:grid-cols-3">
        {items.map((item) => {
          const Icon = icons[item.icon];
          return (
            <div
              key={item.id}
              className={cn(
                "bg-background relative cursor-default overflow-hidden rounded-lg border p-2 text-left",
              )}
            >
              <div className="flex flex-col justify-between rounded-md p-6">
                <div className="flex flex-row justify-between">
                  <Icon className="h-12 w-12" />
                  <div>
                    {item.label && (
                      <Badge variant="secondary">{item.label}</Badge>
                    )}
                  </div>
                </div>

                <div className="space-y-2">
                  <h3 className="font-bold">{item.title}</h3>
                  <p className="text-muted-foreground text-sm">
                    <PayloadLexicalReactRenderer
                      content={
                        item.description as PayloadLexicalReactRendererContent
                      }
                    />
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
}

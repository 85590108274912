"use client";

import { cn } from "~/lib/utils";
import { type Task, type TaskMarketingBlock } from "~/payload-types";

import { TaskTypeEnum, baseTaskSettings } from "../../lib/baseTaskSettings";
import { type getTaskSettingsObject } from "../tasks/settings-fetcher";
import { Badge } from "../ui/badge";

type Props = Omit<TaskMarketingBlock, "blockName"> & {
  taskSettings: Awaited<ReturnType<typeof getTaskSettingsObject>>;
};

export function TaskMarketingBlockRender({
  title,
  subtitle,
  taskSettings,
}: Props) {
  const sortedTaskSettings = Object.entries(taskSettings)
    .toSorted(([, a], [, b]) => {
      return (
        availabilitySortOrder[a.availability] -
        availabilitySortOrder[b.availability]
      );
    })
    .filter(([, taskSettings]) => taskSettings.availability !== "HIDDEN");

  return (
    <section className="container space-y-6 bg-slate-50 py-8 md:py-12 lg:py-24 dark:bg-transparent">
      <div className="mx-auto flex max-w-[58rem] flex-col items-center space-y-4 text-center">
        <h2 className="font-heading text-3xl leading-[1.1] sm:text-3xl md:text-6xl">
          {title}
        </h2>
        <p className="text-muted-foreground max-w-[85%] leading-normal sm:text-lg sm:leading-7">
          {subtitle}
        </p>
      </div>
      <div className="-m-4 grid auto-rows-min gap-4 sm:grid-cols-2 md:grid-cols-3">
        {sortedTaskSettings.map(([_taskType, taskSettings]) => {
          const taskType = TaskTypeEnum.parse(_taskType);
          const Icon = baseTaskSettings[taskType].Icon;
          const availability = availabilityMap[taskSettings.availability];
          return (
            <div
              key={taskType}
              className={cn(
                "bg-background relative cursor-default overflow-hidden rounded-lg border p-2 text-left",
              )}
            >
              <div className="flex flex-col justify-between rounded-md p-6">
                <div className="flex flex-row justify-between">
                  <Icon className="h-12 w-12" />
                  <div>
                    {availability && (
                      <Badge variant="secondary">{availability}</Badge>
                    )}
                  </div>
                </div>

                <div className="space-y-2">
                  <h3 className="font-bold">{taskSettings.title}</h3>
                  <p className="text-muted-foreground text-sm">
                    {taskSettings.description}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
}

const availabilityMap: Record<Task["availability"], string> = {
  ALPHA: "Alpha",
  BETA: "Beta",
  AVAILABLE: "",
  PLANNED: "Planerad",
  HIDDEN: "Dold",
};

const availabilitySortOrder: Record<Task["availability"], number> = {
  AVAILABLE: 0,
  BETA: 1,
  ALPHA: 2,
  PLANNED: 3,
  HIDDEN: 4,
};

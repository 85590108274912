"use client";

import { useIntersectionObserver } from "@uidotdev/usehooks";
import Image from "next/image";
import { useEffect, useRef } from "react";

import { env } from "~/env.mjs";
import { getBlurData } from "~/lib/blurhash";
import { cn } from "~/lib/utils";
import { type Media } from "~/payload-types";

interface Props {
  className?: string;
  src: string;
  thumbnail?: Pick<
    Media,
    "alt" | "url" | "width" | "height" | "blurhash"
  > | null;
}

export function VideoEmbed({ className, src, thumbnail }: Props) {
  const [containerRef, entry] = useIntersectionObserver({
    threshold: 0,
    root: null,
    rootMargin: "50px",
  });

  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (entry?.isIntersecting) {
      void videoRef.current?.play().catch(() => {
        // prevent lint error
      });
    } else {
      videoRef.current?.pause();
    }
  }, [entry]);

  return (
    <div
      ref={containerRef}
      className={cn(className, "relative overflow-hidden")}
    >
      {thumbnail?.url && (
        <Image
          src={`${env.NEXT_PUBLIC_PAYLOAD_URL}${thumbnail.url}`}
          alt={thumbnail.alt ?? "Video thumbnail"}
          width={thumbnail.width ?? 600}
          height={thumbnail.height ?? 400}
          className="absolute"
          placeholder="blur"
          blurDataURL={getBlurData(thumbnail)}
        />
      )}
      <video
        ref={videoRef}
        src={src}
        loop
        preload="none"
        muted
        playsInline
        className="absolute"
      />
    </div>
  );
}

import(/* webpackMode: "eager", webpackExports: ["TaskAiChat"] */ "/app/apps/nextjs/src/app/(app)/elev/[groupId]/uppgift/[taskInstanceId]/task-aichat.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TaskAiGrader"] */ "/app/apps/nextjs/src/app/(app)/elev/[groupId]/uppgift/[taskInstanceId]/task-aigrader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TaskCloze"] */ "/app/apps/nextjs/src/app/(app)/elev/[groupId]/uppgift/[taskInstanceId]/task-cloze/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TaskDictation"] */ "/app/apps/nextjs/src/app/(app)/elev/[groupId]/uppgift/[taskInstanceId]/task-dictation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TaskExitTicket"] */ "/app/apps/nextjs/src/app/(app)/elev/[groupId]/uppgift/[taskInstanceId]/task-exit.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TaskIceBreaker"] */ "/app/apps/nextjs/src/app/(app)/elev/[groupId]/uppgift/[taskInstanceId]/task-icebreaker.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ExamView"] */ "/app/apps/nextjs/src/app/(app)/elev/[groupId]/uppgift/[taskInstanceId]/task-word/exam-view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NoViewSelected"] */ "/app/apps/nextjs/src/app/(app)/elev/[groupId]/uppgift/[taskInstanceId]/task-word/no-view-selected.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Provider"] */ "/app/apps/nextjs/src/app/(app)/elev/[groupId]/uppgift/[taskInstanceId]/task-word/provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ViewSelected"] */ "/app/apps/nextjs/src/app/(app)/elev/[groupId]/uppgift/[taskInstanceId]/task-word/view-selected.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ExamView"] */ "/app/apps/nextjs/src/app/(app)/elev/[groupId]/uppgift/[taskInstanceId]/task-word2/exam-view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NoViewSelected"] */ "/app/apps/nextjs/src/app/(app)/elev/[groupId]/uppgift/[taskInstanceId]/task-word2/no-view-selected.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Provider"] */ "/app/apps/nextjs/src/app/(app)/elev/[groupId]/uppgift/[taskInstanceId]/task-word2/provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TaskLanguagesProvider"] */ "/app/apps/nextjs/src/app/(app)/elev/[groupId]/uppgift/[taskInstanceId]/task-word2/use-task-languages.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ViewSelected"] */ "/app/apps/nextjs/src/app/(app)/elev/[groupId]/uppgift/[taskInstanceId]/task-word2/view-selected.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/nextjs/src/components/blocks/CustomTaskMarketing.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Form"] */ "/app/apps/nextjs/src/components/blocks/form/form.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/nextjs/src/components/blocks/HeroHighlight.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/nextjs/src/components/blocks/RichText.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/nextjs/src/components/blocks/TaskMarketing.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/nextjs/src/components/blocks/ThreeColumns.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/nextjs/src/components/blocks/VideoAndTextEmbed.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/nextjs/src/components/blocks/VideoMp4AndTextEmbed.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/nextjs/src/components/translations/component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Accordion","AccordionItem","AccordionTriggerPlusMinus","AccordionContent"] */ "/app/apps/nextjs/src/components/ui/accordion.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DialogHeader","DialogTitle"] */ "/app/apps/nextjs/src/components/ui/dialog.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/nextjs/src/components/ui/separator.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/nextjs/src/components/ui/tabs.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.9_@opentelemetry+api@1.9.0_@playwright+test@1.45.3_react-dom@18._33bjf7pzi7mx4rsfbv7ax2bb7q/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.9_@opentelemetry+api@1.9.0_@playwright+test@1.45.3_react-dom@18._33bjf7pzi7mx4rsfbv7ax2bb7q/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.9_@opentelemetry+api@1.9.0_@playwright+test@1.45.3_react-dom@18._33bjf7pzi7mx4rsfbv7ax2bb7q/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.9_@opentelemetry+api@1.9.0_@playwright+test@1.45.3_react-dom@18._33bjf7pzi7mx4rsfbv7ax2bb7q/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");

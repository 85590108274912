"use client";

import { Fragment } from "react";

import { HeroHighlight, Highlight } from "~/components/ui/hero-highlight";
import { type HeroHighlightBlock } from "~/payload-types";

type Props = Omit<HeroHighlightBlock, "blockName">;

export function HeroHighlightBlockRender({ texts }: Props) {
  return (
    <HeroHighlight>
      <h1 className="mx-auto max-w-4xl px-4 text-center text-2xl font-bold leading-relaxed text-neutral-700 md:text-4xl lg:text-5xl lg:leading-snug dark:text-white ">
        {texts.map((text, i) =>
          text.type === "highlight" ? (
            <Fragment key={i}>
              <Highlight className="whitespace-nowrap text-black dark:text-white">
                {text.text}
              </Highlight>{" "}
            </Fragment>
          ) : (
            <Fragment key={i}>{text.text} </Fragment>
          ),
        )}
      </h1>
    </HeroHighlight>
  );
}
